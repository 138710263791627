import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import Modal from "../bootstrap/modal";
import TermsAndCondition from "./terms-and-conditions";

export default function TermModal(props) {
  const { onClose } = props;

  return (
    <Modal
      title={"Terms And Conditions"}
      onClose={onClose}
      show
      bodyProps={{style: {padding: 0}}}
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto" className="close-modal-col">
            <Button
              className="close-tnc-btn"
              onClick={() => onClose()}
            >
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid className="no-gutters">
        <Row>
          <Col>
            <TermsAndCondition/>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
