import React, { useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import OtpInput from '../../../otp-input';
import { mfaContext } from '..';

const TOTPPanel = (props) => {
  const {
    otp,
    loading,
    setOtp,
    otpError,
    setOtpError,
    inputFocus,
    setInputFocus,
    setMfaMethod,
  } = useContext(mfaContext);
  useEffect(() => {
    const element = document.getElementById(inputFocus);

    if (element?.value) {
      element.select();
    } else {
      element?.focus();
    }
  }, [inputFocus]);

  return (
    <Container>
      <div className="title mb-2">{"Enter your code"}</div>
      <p className="mfa-sub-heading">
        {loading ? (
          <i className="fad fa-spinner" />
        ) : (
          "We have identified that you have registered a 2FA device."
        )}
      </p>
      <p className="mfa-sub-heading">
        {loading ? (
          <i className="fad fa-spinner" />
        ) : (
          "Enter the code from the registered device to continue."
        )}
      </p>
      {loading ? (
        <i className="fad fa-spinner" />
      ) : (
        <OtpInput
          count={6}
          value={otp}
          onChange={(value) => {
            setOtp(value);
            setOtpError(null);
          }}
          inputFocus={inputFocus}
          setInputFocus={setInputFocus}
        />
      )}
      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      <div className="mfa-other-options-container">
        <span onClick={() => setMfaMethod(null)}>
          {"Use another method to receive the code"}
        </span>
        <span
          onClick={() => {
            window.location = "/login";
          }}
        >
          {"Sign in to a different account"}
        </span>
      </div>
    </Container>
  );
};


export default TOTPPanel