import React, { Fragment, useState } from "react";
import Formsy from "formsy-react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import TextField from "../bootstrap/input";
import { useAsyncSetState } from "use-async-setstate";
import { useRef } from "react";
import { fetch } from "window-or-global";

export default function Verification(props) {
  const {setStateVerification, onClose, isVerification, queryString } = props;
  const codeInput = queryString.substr(8);

  const [loading, setLoading] = useAsyncSetState(false);
  const [isError, setIsError] = useAsyncSetState(false);
  const [errorMessage, setErrorMessage] = useAsyncSetState("");
  const [ verifyCode, setVerifyCode] = useState(codeInput);
  const [ verifySuccess, setVerifySuccess ] = useAsyncSetState(false);
  const formRef = useRef(null);
 

 
  const handleVerify = async (data) => {
    await setLoading(true);

    try {
      const { code } = data;
      if (!code) {
        await setLoading(false);
        return;
      }

      const apiUrl = process.env.BACKEND_URL;

      let result = await fetch(`${apiUrl}rest.api/forgot-verification/${code}`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      });
      result = await result.json();
      if (result && result?.success && result?.redirectUrl) {
        setVerifySuccess(true);
        // window.location.href = result?.redirectUrl;
      } else if (result?.success === false && result?.error === true) {
        await setIsError(true);
        await setErrorMessage("Incorrect verification code");
      } else {
        await setIsError(true);
        await setErrorMessage("Something Went Wrong");
      }
    } catch (err) {
      console.log({err});
      await setIsError(true);
      await setErrorMessage("Something Went Wrong");
      await setLoading(false);
    }

    await setLoading(false);
  }

  return (
    <>
    {verifySuccess ?
    <Fragment>
      <Modal.Body className="fp-modal-body">
        <Container fluid className="no-gutters">
          <Row>
            <Col>
              <p className="verification-title">{"Verification Successful"}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="verification-text text-center">
                {"An sms or email has been sent to your device with your new account details."}
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="fp-modal-footer">
        <Container>
          <Row>
            <Col xs="auto ml-auto" className="request-col">
              {isVerification ?
              <a href="/login">
                <Button
                  className="request-password-btn"
                >
                  {"Try Sign In Account"}
                </Button>
              </a> 
              :
              <Button
                className="request-password-btn"
                onClick={() => {
                  onClose(true);
                }}
              >
                {"Try Sign In Account"}
              </Button>
              }
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Fragment>
    :
    <Fragment>
      <Modal.Body className="fp-modal-body">
        <Formsy
          ref={formRef}
          onValidSubmit={async (data) => {
            return handleVerify(data);
          }}
          onInvalidSubmit={(e) => {
            console.log("error", e);
            setIsError(true);
            setErrorMessage("Invalid Code");
          }}
        >
          <Container fluid className="no-gutters">
          {isError &&
            <Row>
              <Col>
                <div className="alert alert-danger">
                  {errorMessage}
                </div>
              </Col>
            </Row>
            }
            <Row>
              <Col>
                <p className="verification-title">{"Enter verification code to continue"}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="verification-text">{"An sms or email has been sent to your device, enter the one time code to verify your account to reset your password."}</p>
                <p className="verification-text">{"If you do not receive a verification code, please go back and check your details and try again."}</p>
              </Col>
            </Row>
            <Row className="verification-input field-row">
              <Col>
                {isVerification ? 
                <TextField
                  disabled={loading}
                  type={"number"}
                  autoFocus
                  inputMode="numeric"
                  label="Verification Code"
                  value={verifyCode}
                  onChange={e => setVerifyCode(e.target.value)}
                  name="code"
                  placeholder="Verification Code"
                  defaultValue={codeInput}
                  required
                  autoComplete="one-time-code"
                />
                :
                <TextField
                  disabled={loading}
                  type={"number"}
                  autoFocus
                  inputMode="numeric"
                  label="Verification Code"
                  name="code"
                  placeholder="Verification Code"
                  defaultValue=""
                  required
                  autoComplete="one-time-code"
                />
                }
              </Col>
            </Row>
            
          </Container>
        </Formsy>
      </Modal.Body>
      <Modal.Footer className="fp-modal-footer">
        <Container>
          <Row>
            <Col xs="auto mr-auto" className="cancel-col">
              {isVerification ? 
              <a href="/login">
                <Button
                  className="cancel-request-btn"
                >
                  {"Cancel"}
                </Button>
              </a> 
              :
              <Button
                className="cancel-request-btn"
                onClick={async () => {
                  setStateVerification(false)
                }}
              >
                <i className="fas fa-arrow-left mr-2" />
                {"Return"}
              </Button>
              }
            </Col>
            <Col xs="auto ml-auto" className="request-col">
              <Button
                type="submit"
                className="request-password-btn"
                disabled={loading}
                onClick={() => formRef.current.submit()}
              >
                {"Reset Password"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Fragment>
    }
    </>
  )
}