import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import config from "../../config";
import Footer from "../section/footer";
import MFAOption from "./mfa"
import { Login } from "@slbnsc/react-portal";
import RegisterForm from "../section/register";
import logoBrand from "../../images/newcoll/New College Crest Blue.png";
import ForgotPassword from "../forgot-password/index";
import Terms from "../portal/terms";
import PrivacyPolicy from "../portal/privacy-policy";

const  LoginPage = (props) =>{
  const [register, setRegister] = useState(false);
  const [fullHeight, setFullHeight] = useState(true);
  const [showTerm, setTerm] = useState(false);
  const [showPolicy, setPolicy] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [mfa, setMfa] = useState(null);
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  const queryString = window.location.search;
  let isVerification  = queryString.includes("?verify=");


  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  return (
  <>
    {showTerm && <Terms onClose={() => setTerm(false)} />}
    {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
    <Container fluid className={`fh no-gutters login-main-body login-page ${fullHeight ? "vh-page" : ""} `}>
      <Container className="login-main-header-md">
        <header className="login-main-logo-md">
          <a href="https://newcollege.unsw.edu.au/" target="_blank">
            <img src={logoBrand} className="image-brand-logo-md" alt="brand-logo"/>
            <img className="image-brand-logo-md"  src="https://cdn.vostro.app/cdn/hotspots/newcoll/New College Crest black NCV Green.png" alt="college-brand-logo-2"/>
          </a>
        </header>
      </Container>
      <Row className="fh-element align-items-center">
        <Col>
          {(showForgotPassword || isVerification) && <ForgotPassword onClose={onCloseForgotPassword} isVerification={isVerification} queryString={queryString} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container>
            <Row className="main-login-row">
              <img className="main-bg-styling"  src="https://cdn.vostro.app/cdn/hotspots/newcoll/new college photo.png" alt="college-brand-logo-1"/>
              <Col lg={6} xl={7} md={0} className="mb-5 mb-lg-0 login-brand-container">
               
                <div className="login-brand-primary-section">
 
                  <img className="college-brand-logo"  src="https://cdn.vostro.app/cdn/hotspots/newcoll/New College Crest-black NC Blue.png" alt="college-brand-logo-1"/>
                  <img className="college-brand-logo"  src="https://cdn.vostro.app/cdn/hotspots/newcoll/New College Crest black NCV Green.png" alt="college-brand-logo-2"/>

                  <p className="login-brand-heading">
                    {"New College"} <br/>
                    {"Communities"}
                  </p>    
                </div>
                
                {!mfa ? 
                  <div className="login-brand-secondary-section">
                    {"The New College Communities are home to 562 undergraduates"} <br/>
                    {"and postgraduates studying and researching at the University of"} <br/>
                    {"New South Wales."}
                  </div>
                  :
                  <div className="login-brand-secondary-section-hidden">
                    {"The New College Communities are home to 562 undergraduates"} <br/>
                    {"and postgraduates studying and researching at the University of"} <br/>
                    {"New South Wales."}
                  </div>
                }

                {!mfa ?   
                <div className="login-brand-secondary-section-2">
                  {"The New College Communities are home to 562 undergraduates"}
                  {"and postgraduates studying and "} <br/>
                  {"researching at the University of New South Wales."}
                </div>
                  :
                <div className="login-brand-secondary-section-2-hidden">
                  {"The New College Communities are home to 562 undergraduates"}
                  {"and postgraduates studying and "} <br/>
                  {"researching at the University of New South Wales."}
                </div>
                }
                
              </Col>
              <Modal
                show={mfa}
                className="login-mfa-panel-modal"
                onHide={()=> {}}
              >
                <MFAOption mfa={mfa} setMfa={setMfa} authUrl={config.auth} portalUrl={config.portal} apiUrl={config.backend}/>
              </Modal>
              <Col xl={5} lg={6} md={12} className="login-column-section">

                <div className="form-brand-heading-md">
                    <p className="login-heading">{"New College"} <br/>
                    {"Communities"} </p>

                    <p className="form-brand-subheading">
                      {"The New College Communities are home to 562 undergraduates"}
                      {"and postgraduates studying and "} <br/>
                      {"researching at the University of New South Wales."}
                    </p>
                </div>    
                <div className="login-section-container">
                {!mfa && (
                  <div className="login-container">
                    <p className="login-title">
                      {"Login to your account"}
                    </p>
                  
                    <Login
                      inputClassName={"login-form-input"}
                      noLabels={false}
                      labelClassName={"form-input-label"}
                      authUrl={config.auth} 
                      portalUrl={config.portal} 
                      apiUrl={config.backend}
                      noPlaceHolders={true}
                      floating={true}
                      withErrorOnButton={true}
                      inline={true}
                      withUserIcon={true}
                      loginBtnLabel={"Sign In"}
                      withPasswordIcon={true}
                      withRecoverPasswordLink={true}
                      setMfa={setMfa} 
                      recoverPasswordLink={<>
                        <a className="forgot-password" onClick={handleClickForgotPassword}>{"Recover Password"}</a>
                      </>}
                    />

                     
                 
                    
                  </div> )}
                {!mfa && (
                  <div className="login-form-footer">
                      <a href="/">
                        {`© ${year} New College Communities. `}
                      </a> <br/>
                      {`All rights reserved. | `} 
                      <a onClick={() => setTerm(true)}>
                        {"Terms And Conditions"}
                      </a>
                      <div>
                        <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
                      </div>
                  </div>)}
                </div>
              </Col>
            </Row>
          </Container>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer year={year} setTerm={setTerm}/>
        </Col>
      </Row>
    </Container>
  </>
  );
}


export default LoginPage;

