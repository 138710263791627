import React, { createContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-mini";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { fetch, localStorage, window } from "window-or-global";

import {
  createVerificationMutation,
  getAdminGeneratedVerification,
  resendVerificationMutation,
  validateVerification,
} from "../../logic/verification";
import { toGlobalId } from "../../utils/graphql";
import OtpInput from "../../otp-input";
import EmailPanel from "./panels/mfa-email-panel";
import SMSPanel from "./panels/mfa-sms-panel";
import MFAAdminGeneratedPanel from "./panels/mfa-admin-generated-panel";
import TOTPPanel from "./panels/mfa-totp-panel";
import SelectPanel from "./panels/select-panel";

export const mfaContext = createContext();

export const characterHide = (text) => {
  if (!text) {
    return "";
  }

  if (text?.trim() === "") {
    return text;
  }

  const textsSplitted = text?.split(/[@]/gim);
  let processedText = "";

  textsSplitted.map((t) => {
    const replacerPercentage = 0.5; // in decimal form
    const replacerCount =
      Math.floor(t.length * replacerPercentage) > 1
        ? Math.floor(t.length * replacerPercentage)
        : 0;
    processedText += "*".repeat(replacerCount) + t.slice(replacerCount);
  });

  return processedText;
};

const OTP_VALIDITY = 600;

export default function MFASelector(props) {
  const { setMfa, mfa, apiUrl, authUrl, portalUrl } = props;
  const { user, hasUserTOTP } = mfa;
  const [mfaMethod, setMfaMethod] = useState(null);
  const [allowedOptions, setAllowedOptions] = useState(["admin-generated-otp"]);
  const [createVerification] = useMutation(createVerificationMutation);
  const [resendVerfication] = useMutation(resendVerificationMutation);
  const [loading, setLoading] = useState(null);
  const [requestCode, setRequestCode] = useState(null);
  const [retryTimer, setRetryTimer] = useState(0);
  const [retryEnabled, setRetryEnabled] = useState(false);

  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const [inputFocus, setInputFocus] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);
  const [authDetails, setAuthDetails] = useState(false);

  const formRef = useRef(null);

  const {
    data: validateVerificationData,
    startPolling,
    stopPolling,
  } = useQuery(validateVerification, {
    variables: { requestCode },
  });
  const requestCodeVerified =
    validateVerificationData?.classMethods?.Verification?.validateVerification;

  const { data } = useQuery(getAdminGeneratedVerification, {
    variables: {
      userId: toGlobalId("User", user?.id),
    },
  });
  const activeVerification =
    data?.classMethods?.Verification?.adminGeneratedVerification;

  useEffect(() => {
    if (requestCodeVerified) {
      handleApprove();
      stopPolling();
    }
  }, [requestCodeVerified]);

  useEffect(() => {
    if (user.mobileNumber) {
      setAllowedOptions((prev) => [...prev, "sms"]);
    }
    if (user.email) {
      setAllowedOptions((prev) => [...prev, "email"]);
    }
    if (hasUserTOTP) {
      setAllowedOptions((prev) => [...prev, "user-totp"]);
    }
  }, []);

  useEffect(() => {
    setOtp(Array(6).fill("")); // resets otp value
    setOtpError(null); // resets otp error
    setInputFocus(null); // resets input focus

    if (mfaMethod === "sms") {
      createVerification({
        variables: {
          type: "SMS",
          userId: toGlobalId("User", user?.id),
          name: "Sms Verification",
          template: "2fa-portal",
        },
        awaitRefetchQueries: true,
      }).then((result) => {
        const { data } = result;
        console.log("create result", data);
        setRequestCode(
          data?.classMethods?.Verification?.createVerification?.id
        );
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });
    } else if (mfaMethod === "email") {
      createVerification({
        variables: {
          type: "EMAIL",
          userId: toGlobalId("User", user?.id),
          name: "Email Verification",
          template: "2fa-portal",
        },
        awaitRefetchQueries: true,
      }).then((result) => {
        const { data } = result;
        setRequestCode(
          data?.classMethods?.Verification?.createVerification?.id
        );
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });
    } else if (mfaMethod === "user-totp") {
      createVerification({
        variables: {
          type: "TOTP",
          userId: toGlobalId("User", user?.id),
          name: "Totp Verification",
        },
        awaitRefetchQueries: true,
      }).then((result) => {
        const { data } = result;
        setRequestCode(
          data?.classMethods?.Verification?.createVerification?.id
        );
      });
    }
  }, [mfaMethod]);

  useEffect(() => {
    let interval;

    if (retryTimer === 0) {
      setOtp(Array(6).fill("")); // resets otp value
      setInputFocus(null); // resets input focus
      setOtpError(null); // resets otp error
    }
    if (retryTimer > 0 && !requestCodeVerified) {
      interval = setInterval(() => {
        setRetryTimer((prev) => {
          const newTime = prev - 1;

          if (newTime === 0) {
            stopPolling();
            setRetryEnabled(true);
          }

          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [retryTimer]);

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleOtpSubmit(otp.join(""));
    }
  }, [otp]);

  const handleRetry = () => {
    setOtp(Array(6).fill("")); // resets otp value
    setInputFocus(null); // resets input focus
    setOtpError(null); // resets otp console.error();

    if (requestCode) {
      resendVerfication({
        variables: { requestCode },
      }).then(() => {
        setRetryEnabled(false);
        setRetryTimer(OTP_VALIDITY);
        startPolling(1000);
      });
    }
  };

  const handleApprove = async (verificationId = null) => {
    try {
      const response = await fetch(`${apiUrl}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          ...mfa.loginDetails,
          requestCode: verificationId || requestCode,
        }),
      });
      const status = response.status;
      const data = await response.json();

      if (status === 200) {
        if (data.jwtToken) {
          localStorage.setItem("authToken", data.jwtToken);
        }
        setAuthDetails(mfa.loginDetails);
        if (authUrl && authUrl !== "") {
          formRef.current.submit();
        } 
          return window.location = portalUrl;
      }

      console.log("503 redirect?"); // eslint-disable-line
      return window.location = "/login";
    } catch (err) {
      console.log("ERROR >>> ", err); // eslint-disable-line
      console.log("err 503 redirect?"); // eslint-disable-line
      return undefined;
    }
  };


  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const response = await fetch(
      `${apiUrl}rest.api/verify/${requestCode}:${otp}`,
      {
        method: "GET",
      }
    );

    const data = await response.json();
    if (data.success) {
      handleApprove();
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  
  return (
    <mfaContext.Provider
      value={{
        user,
        loading,
        otp,
        setOtp,
        setOtpError,
        inputFocus,
        setInputFocus,
        otpError,
        handleApprove,
        requestCodeVerified,
        retryEnabled,
        retryTimer,
        handleRetry,
        setMfaMethod,
        stopPolling,
        setRetryTimer,
        activeVerification
      }}
    >
      <form ref={formRef} name="login" action={authUrl} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input type="hidden" name="dst" value={`${portalUrl}`} />
        <input type="hidden" name="username" value={authDetails?.userName} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>
      <div className="mfa-container">
        {mfaMethod && mfaMethod === "sms" && <SMSPanel />}
        {mfaMethod && mfaMethod === "email" && <EmailPanel />}
        {mfaMethod && mfaMethod === "user-totp" && <TOTPPanel />}
        {mfaMethod && mfaMethod === "admin-generated-otp" && <MFAAdminGeneratedPanel />}
        {!mfaMethod && <SelectPanel setMfaMethod={setMfaMethod} allowedOptions={allowedOptions} />}
      </div>
    </mfaContext.Provider>
  );
}
