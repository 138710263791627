import React, { useRef, Fragment } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import Formsy from "formsy-react";
import "../../style/custom/forgot-password.scss";
import TextField from "../bootstrap/input";
import Captcha from "../bootstrap/captcha";
import Verification from "./verification";


export default function Index(props) {
  const {onClose, isVerification, queryString} = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [captchaRefreshKey, setRefreshKey] = useAsyncSetState(new Date());
  const [showVerification, setVerification] = useAsyncSetState(false);

  const formRef = useRef(null);

  const handleSubmit = async ({ email, captcha, captchaKey }) => {
    try {
      await setLoading(true);
      const response = await fetch(`${process.env.BACKEND_URL}rest.api/forgot-request`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          captcha,
          captchaKey
        }),
      });
      switch(response.status) {
          case 503:
            await setStateAsync(() => (<>
              {"An error has occurred"}
            </>));
            await setRefreshKey(new Date());
            break;
          case 401:
            await setRefreshKey(new Date());
            const {error} = await response.json();
            switch(error) {
              case "EEXISTS":
                await setErrorMessage(() => (<>
                  {"This email address already registered, "}
                  <Link to="/forgot-password">{"Click Here"}</Link>{" to reset your password"}
                </>));
                break;
              case "ECAPTCHA":
                await setErrorMessage(() => (<>
                  {"Invalid captcha code provided"}
                </>));
                break;
              default:
                await setErrorMessage(error);
                break;
            }
            break;
          case 200:
            await setErrorMessage(null);
            await setVerification(true);
            // return window.location = `/welcome?firstName=${data.firstName}&userName=${userName}`;
        }
      } catch (err) {
        console.log("err", err);
        await setErrorMessage("An error has occurred");
      }
      return setLoading(false);
  }

  return (
    <Modal
      aria-hidden={true}
      size={"md"}
      backdrop="static"
      keyboard={true}
      show={true}
      onHide={onClose}
      centered
      className="fp-modal"
    >
        {(showVerification || isVerification) ? 
          <Verification setStateVerification={setVerification} onClose={onClose} isVerification={isVerification} queryString={queryString} /> : (
          <Fragment>
            <Modal.Body className="fp-modal-body">
              {errorMessage &&
                <Row>
                  <Col>
                    <div className="alert alert-danger">
                      {errorMessage}
                    </div>
                  </Col>
                </Row>
              }
              <Formsy ref={formRef} onValidSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <p className="forgot-password-heading">{"Reset your password"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="forgot-password-subheading">
                      Enter the email you use to register, <br/>
                      and we'll help you create a new Password
                    </p>
                  </Col>
                </Row>
                <Row className="field-row mb-2">
                  <Col xs={12} className="forgot-password-field">
                    <TextField
                      className="forgot-password-form-field"
                      name="email"
                      label="Email Address"
                      autoComplete="off"
                      placeholder="Email Address"
                      disabled={loading}
                      required
                    />
                  </Col>
                </Row>
                <Row className="field-row">
                  <Col>
                    <Captcha name="captcha" refreshKey={captchaRefreshKey} />
                  </Col>
                </Row>
              </Formsy>
            </Modal.Body>
            <Modal.Footer className="fp-modal-footer">
              <Container>
                <Row>
                  <Col xs="auto ml-auto" className="cancel-col">
                    <Button
                      className="cancel-request-btn"
                      colour="light"
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        return onClose();
                      }}
                    >
                      {"Cancel"}
                    </Button>
                  </Col>
                  <Col xs="auto" className="request-col">
                    <Button
                      className="request-password-btn"
                      type="submit"
                      disabled={loading}
                      onClick={() => formRef.current.submit()}
                    >
                      {"Request"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  )
}
